import React, { useContext, useEffect } from 'react'
import { graphql } from 'gatsby'
import { sHtml, s } from '../clever-ui-kit/style'
import { T } from '../clever-ui-kit/typo'
import { sTitle, sBlog, sTheme } from './blog'
import PricingBox from '../components/pricingBox'


// CONTEXT
import { StateContext, DispatchContext, initialState } from '../context'

// COMPONENTS CONST
import SEO from '../components/seo'
import { IntroBlog } from '../clever-ui-kit/intros'
import ContactForm from '../clever-ui-kit/forms/ContactForm'

export default function ServiceTemplate({ data }) {
  const state = useContext(StateContext)
  const dispatch = useContext(DispatchContext)
  // const language = state?.language ? state.language : initialState.language
  const theme = state?.theme ? state.theme : initialState.theme

  const service = data.thisService?.frontmatter
  const html = data.thisService?.html

  const pricing = service?.pricingTable

  /*eslint-disable */
  useEffect(() => {
    dispatch({ type: 'SET_MENU', payload: false })
  }, [])

  useEffect(() => {
    if (service.language)
      dispatch({ type: 'SET_LANGUAGE', payload: service.language })
  }, [])
  /*eslint-enable */

  return (
    <>
      <SEO
        title={service.title}
        description={service.description}
        image={service.image?.publicURL}
        slug={'/' + service.service_slug.toLowerCase().trim()}
      />
      <IntroBlog
        title={service.title}
        subtitle={service.description}
        image={service.image}
        theme={theme}
        language={service.language}
      />
      <div css={sTitle}>
        <T
          d={64}
          t={48}
          m={32}
          mb={0.5}
          bold
          variant="h1"
          extraCss={sTitle.headline}>
          {service.title}
        </T>
        <T d={20} t={18} m={14} o={1} normal>
          {service.description}
        </T>
      </div>
      <article
        css={[sHtml, sBlog, sTheme(theme), serviceWidth]}
        dangerouslySetInnerHTML={{ __html: html }}
      />
      {pricing && (
        <div css={[stylesPricing.sPricingWrapper]}>
          {pricing.map((plan, id) => (
            <PricingBox id={id} data={plan} />
          ))}
        </div>
      )}
      <div css={{ marginBottom: '4rem' }}>
        <ContactForm theme={theme} />
      </div>
    </>
  )
}

const serviceWidth = {
  maxWidth: '1000px',
}

const stylesPricing = {
  sPricingWrapper: {
    maxWidth: '1128px',
    margin: '0 auto',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: 30,
    [s.md_down]: {
      padding: '0 20px'
    },
    [s.sm_down]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [s.xs_down]: {
      gridTemplateColumns: 'repeat(1, 1fr)',
    }
  },
  sPricingBox: {
    boxSizing: 'border-box',
    width: '100%',
    background: '#fff',
    boxShadow: '0px 16px 96px rgba(0,0,0,0.04)',
    borderRadius: '32px',
    padding: '32px 0',
  },
}

export const query = graphql`
  query($slug: String) {
    thisService: markdownRemark(
      fileAbsolutePath: { regex: "/services/" }
      frontmatter: { service_slug: { eq: $slug } }
    ) {
      html
      frontmatter {
        title
        service_slug
        language
        description
        pricingTable {
          planDesc
          planDescBelow
          planDescBelowExpand
          planDescExpand
          planName
          planPriceString
          planPriceNet
          planAddon
          planTime
          planColor
        }
        image {
          name
          publicURL
          childImageSharp {
            fluid(
              maxWidth: 1920
              quality: 100
              toFormat: JPG
              jpegProgressive: true
            ) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
