import React, { useState } from 'react'
import ReactMarkdownWithHtml from 'react-markdown'
import { graphql } from 'gatsby'

const PricingBox = ({id, data}) => {

  const [readMoreStart, setReadMoreStart] = useState(false)

  const handleReadMore = (id) => {
    setReadMoreStart(prev => !prev)
  }

  const handleShowMore = (id) => {
    return readMoreStart
  }


  return (
    <>
      <div className={data.planColor} css={[stylesPricing.sPricingBox]} id={id} key={id}>
        <div css={[stylesPricing.sPricingNoPrice]}>
          <h2 css={{}}>{data.planName}</h2>
          <div>
            <ReactMarkdownWithHtml children={data.planDesc} allowDangerousHtml />
          </div>
          {/* <ReactMarkdownWithHtml children={data.planDesc} allowDangerousHtml /> {handleShowMore(id) ? <ReactMarkdownWithHtml children={data.planDescExpand} allowDangerousHtml /> : null} {data.planDescExpand && (<span onClick={() => handleReadMore(id)}>{handleShowMore(id) ? 'Czytaj mniej' : 'Czytaj więcej'}</span>)} */}
        </div>
        <div className="sPricingPrice" css={[stylesPricing.sPricingPrice]}>
          <div>
            <h4>{data.planPriceString}</h4>
            <div css={[stylesPricing.sPricingPriceInner]}>
              <h6>PLN {data.planPriceNet && (<span>*</span>)}</h6>
              <p>{data.planTime}</p>
            </div>
          </div>
          {data.planAddon && (
            <p className='sPricingPriceAddon'>{data.planAddon}</p>
          )}
        </div>
        <div css={[stylesPricing.sPricingNoPrice]}>
          <ReactMarkdownWithHtml children={data.planDescBelow} allowDangerousHtml />
          {handleShowMore(id) ? (
            <ReactMarkdownWithHtml
              children={data.planDescBelowExpand}
              allowDangerousHtml
            />
          ) : null }
          {data.planDescBelowExpand && (
            <span onClick={() => handleReadMore(id)}>{handleShowMore(id) ? 'Czytaj mniej' : 'Czytaj więcej'}</span>
          )}
        </div>
        <div css={stylesPricing.sPricingActionWrapper}>
          <a
            href="mailto: biuro@reklamadlabiznesu.pl"
            rel="noopener noreferrer">
            Zamów
          </a>
          {data.planPriceNet && (
            <span>* Cena netto</span>
          )}
        </div>
      </div>
    </>
  )
}

const stylesPricing = {
  sPricingWrapper: {
    maxWidth: '1128px',
    margin: '0 auto',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: 30,
  },
  sPricingBox: {
    boxSizing: 'border-box',
    width: '100%',
    background: '#fff',
    boxShadow: '0px 16px 96px rgba(0,0,0,0.04)',
    borderRadius: '32px',
    padding: '32px 0',
    '&.mediumPlan': {
      color: '#00b000',
      a: {
        borderColor: '#00b000',
        '&:hover': {
          backgroundColor: '#00b000',
        }
      },
      '.sPricingPrice': {
        background: 'linear-gradient(to right, transparent, rgba(0, 176, 0, 0.2))',
      }
    },
    '&.premiumPlan': {
      color: '#fff',
      background: 'linear-gradient(to right bottom, rgb(219, 0, 0), rgb(151, 0, 0))',
      a: {
        borderColor: '#fff',
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: '#fff',
          color: 'rgb(151, 0, 0)',
        }
      }
    },
  },
  sPricingNoPrice: {
    padding: '0 32px',
    div: {
      padding: '32px 0'
    },
    h2: {
      textAlign: 'center',
      fontSize: '1.5rem'
    },
    p: {
      fontSize: '.85rem',
      margin: '32px 0',
      minHeight: 80,
    },
    'ul, ol': {
      fontSize: '.85rem',
      paddingLeft: '20px',
      margin: 0,
    },
    span: {
      display: 'inline',
      fontSize: '.85rem',
      opacity: .6,
      cursor: 'pointer',
      textDecoration: 'underline',
      '&:hover': {
        textDecoration: 'none'
      }
    }
  },
  sPricingPrice: {
    padding: '0',
    marginBottom: '32px',
    textAlign: 'center',
    backgroundImage:
      'linear-gradient(to right, transparent , rgba(0, 0, 0, 0.2))',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textTransform: 'uppercase',
    div: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      lineHeight: '90px',
      h4: {
        fontSize: '2.25rem',
        a: {
          textDecoration: 'none',
          color: '#0076B0',
          whiteSpace: 'pre',
        },
      },
      p: {
        margin: 0,
        opacity: 0.85,
        fontSize: 14,
        lineHeight: 1,
      },
      h6: {
        fontSize: '1.25rem',
        lineHeight: 1,
        textAlign: 'left',
        marginBottom: '4px',
      },
    },
    '.sPricingPriceAddon': {
      margin: 0,
      opacity: 1,
      paddingBottom: '1rem',
      fontSize: 14,
      lineHeight: 1,
      lineHeight: 1.15
    },
  },
  sPricingPriceInner: {
    display: 'block !important',
    marginLeft: '5px',
    flexDirection: 'column',
    justifyContent: 'flex-start !important',
  },
  sPricingActionWrapper: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    a: {
      display: 'block',
      margin: '30px auto 5px',
      backgroundColor: 'white',
      borderRadius: '15px',
      padding: '7px 25px',
      border: '2px solid #333',
      cursor: 'pointer',
      textTransform: 'uppercase',
      overflow: 'hidden',
      position: 'relative',
      transition: 'all 550ms cubic-bezier(0.19, 1, 0.22, 1)',
      fontWeight: 'bold',
      '&:hover': {
        transition: 'all 2550ms cubic-bezier(0.19, 1, 0.22, 1)',
        transition: '0.5s',
        backgroundColor: '#333',
        color: '#fff',
      },
    },
    span: {
      textAlign: 'center',
      display: 'block',
      marginTop: '32px',
      fontSize: '.85rem'
    }
  },
}

export default PricingBox
